import React, { Fragment, useEffect } from 'react';

import { mapToKiwiVIP } from './mapper';
import SignupBanner from './SignupBanner';
import SignupBannerLarge from './SignupBannerLarge';
import { KiwiVipWrapper } from './SingupBanner.styles';
import { useFetcher } from '@remix-run/react';
import { KiwiVIPSignup } from '~/_models';
import { useClientEnv } from '~/contexts/ClientEnvContext';
// import { SiteMetaData } from '~/routes/api.site-meta-data'

const SignupBannerWrapper = (props: {
  version: string;
  anchored?: boolean;
}) => {
  const kiwiVipSignupFetcher = useFetcher();
  //   const siteMetaDataFetcher = useFetcher();
  const { emailValidationToken, apiUrl } = useClientEnv();
  useEffect(() => {
    kiwiVipSignupFetcher.load('/api/kiwivip-signup-data');
    // siteMetaDataFetcher.load('/api/site-meta-data');
  }, []);

  if (kiwiVipSignupFetcher.state === 'loading' || !kiwiVipSignupFetcher.data) {
    return <div>Loading...</div>;
  }

  // must check data and state both
  //   if (siteMetaDataFetcher.state === 'loading' || !siteMetaDataFetcher.data) {
  //     return <div>Loading...</div>;
  //   }

  //   const siteMetadata = siteMetaDataFetcher.data as SiteMetaData;
  const data = kiwiVipSignupFetcher.data as KiwiVIPSignup;
  data.elements;
  const { version, anchored } = props;
  const kiwiVipData = mapToKiwiVIP(data.elements);
  //   const apiUrl = siteMetadata.apiUrl;
  //   const emailValidationToken = siteMetadata.emailValidationToken;
  return (
    <Fragment>
      {
        <KiwiVipWrapper id="kiwivip-wrapper" ancorhored={anchored}>
          {version === 'standard' ? (
            <SignupBanner
              emailValidationToken={emailValidationToken}
              apiUrl={apiUrl}
              {...kiwiVipData}
            />
          ) : (
            <SignupBannerLarge
              emailValidationToken={emailValidationToken}
              apiUrl={apiUrl}
              {...kiwiVipData}
            />
          )}
        </KiwiVipWrapper>
      }
    </Fragment>
  );
};

export default SignupBannerWrapper;
