import { KenticoImage, KenticoChoice } from '@utils/KontentTypes';
import { convertKontentColorToHex } from '@utils/Helpers';

interface KiwiVipVisibleProps {
  generalPageContentKiwivipSignup: {
    value: KenticoChoice[];
  };
  generalPageContentKiwivipSignupVersion: {
    value: KenticoChoice[];
  };
}

export const mapToKiwiVIPVisible = (data: KiwiVipVisibleProps) => {
  const kiwiVipSignup = data.generalPageContentKiwivipSignup.value[0].codename; //codename visible or hide
  const kiwiVipSignupVersion =
    data.generalPageContentKiwivipSignupVersion.value[0].codename; //codename standard, large or none
  return {
    visible: kiwiVipSignup === 'visible',
    version: kiwiVipSignupVersion,
  };
};

interface KiwiVipSignupProps {
  title: {
    value: string;
  };
  image: {
    value: Array<KenticoImage>;
  };
  buttonText: {
    value: string;
  };
  buttonButtonColorTheme: {
    value: KenticoChoice[];
  };
  buttonButtonType?: {
    value: KenticoChoice[];
  };
  buttonUrlSlug: {
    value: string;
  };
  carMake: any;
  motorbikeMake: any;
  trailerMake: any;
}

export const mapToKiwiVIP = (data: any) => {
  const {
    title,
    image,
    buttonText,
    buttonButtonColorTheme,
    buttonButtonType,
    buttonUrlSlug,
  } = data;
  const imageAsset =
    image === undefined ? { url: '', description: '' } : image.value[0];

  if (buttonButtonType === undefined) {
    throw new TypeError('KiwiVIP Mapper Error: button type is undefined.');
  }

  const carMake = data.carMake.value.map((item: any) => {
    return {
      key: item.name,
      text: item.name,
      value: item.name,
    };
  });

  const motorbikeMake = data.motorbikeMake.value.map((item: any) => {
    return {
      key: item.name,
      text: item.name,
      value: item.name,
    };
  });

  const trailerMake = data.trailerMake.value.map((item: any) => {
    return {
      key: item.name,
      text: item.name,
      value: item.name,
    };
  });

  return {
    title: title.value,
    image: {
      url: imageAsset.url,
      description: imageAsset.description,
    },
    button: {
      type: buttonButtonType.value[0].codename as string,
      theme: convertKontentColorToHex(buttonButtonColorTheme.value[0].codename),
      text: buttonText.value,
      url: buttonUrlSlug.value,
    },
    carMake: carMake,
    motorbikeMake: motorbikeMake,
    trailerMake: trailerMake,
  };
};
