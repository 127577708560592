import React from 'react'
import styled from '@emotion/styled'
import {KenticoImage} from '@components/elements/index'
import mq from '@styles/mq'
import {Segment, Container,Grid, FormGroup, GridRow, FormField,Form} from 'semantic-ui-react'
import {H4} from '@styles/Global.styles'

export const BannerSegment = styled(Segment)`
        background-color: #000000 !important;
        margin: 0 !important;

        .row {
          ${props => mq({
            display:['inline !important;','inline !important;','inline !important;', 'flex !important;']
          })}
          text-align: center !important;
        }
`

export const BannerContainer = styled(Container)`
`
export const KiwiVipWrapper  = styled(({...props}) => <div {...props}/>)`
  width: 100%;
  bottom: 0;
  ${props => mq({
    position:['relative','relative',`${props.anchored ? 'absolute' : 'relative'}`]
  })}
  background: #000;

  .row {
    padding: 9px !important;
    div {
      ${props => mq({
        padding:['3px !important','3px !important', '3px !important', '3px !important']
      })}
    }
  }

`
export const BannerGrid = styled(Grid)`
    &&&&{
        margin-top: 2px;
        margin-bottom: 12px;
    }
`
export const BannerGridTitleColumn = styled(Grid.Column)`
    margin: auto !important;
    padding-top: 18px;
`
export const BannerGridColumn = styled(Grid.Column)`
    margin-top: auto;
`
export const BannerGridButtonColumn = styled(Grid.Column)`
    margin-top: 3px !important;
    ${props => mq({
      marginLeft:['0px !important','10px !important', '10px !important', '10px !important']
    })}
    button {
      min-width: 160px !important;
    }
`
export const BannerTitle = styled(H4)`
    color: #FFFFFF;
    line-height: 50px !important;
    margin-top: 0px !important;
    ${props => mq({
      textAlign:['center','center','center','right']
    })}
    ${props => mq({
      marginRight:['0px !important','0px !important','0px !important','15px !important']
    })}
`

export const SignupFormGroup = styled(FormGroup)`
    &&&&{
        margin: 0;
    }
`

/*large signup banner */


export const BannerImageSegment = styled(Segment)`
  && {
    border: none;
    padding: 0;
    margin: 0;
  }
`

export const BannerContentContainer = styled(GridRow)`
  ${mq({
    height:['351px','351px','356px']
  })}
  position: relative;
  width: 100%;
`;

export const BannerHeading = styled(H4)`
  color: white;
`;

export const BannerImageContainer = styled.div`
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
`;
export const BannerImage = styled(KenticoImage)`
  width: 100%;
  height: 100%;
`;

export const BannerContent = styled(Container)`
  ${mq({
    marginTop: ['-170px','-170px','-356px'], //same as BannerContainer height
    marginBottom: ['40px','40px','120px'], //the margin bottom
    height:['90px','90px','116px'] //the content area height
  })}
  display:block;
  text-align: center;
  position: relative;
  color: white;
`;

export const SignupFormField = styled(FormField)`

  & > label{
    color: #FFFFFF !important;
    text-align: left;
  }
`
export const SimpleInputFormField = styled(Form.Input)`

  & > .input{
    height: 50px;
  }
`

export const BannerButtonContainer = styled.div`
  margin: 30px 0;
`