import React from "react"
import { Grid, Form } from "semantic-ui-react"
import { KpButton } from "@components/elements/index"
import { Formik } from "formik"
import * as Yup from "yup"

import {
  BannerSegment,
  BannerGrid,
  BannerTitle,
  BannerGridColumn,
  BannerGridTitleColumn,
  BannerGridButtonColumn,
} from "./SingupBanner.styles"
import { ButtonType } from "@utils/KontentTypes"
import { Paragraph1 } from "@styles/Global.styles"
import theme from "@styles/theme"
import { SubscriptionService } from "@services/index"
import {
  FormikFieldEmailValidationInput,
  KpFormField,
  KpFormInputErrorMessage,
} from "@styles/Global.forms"
import { EmailValidationService } from "@services/EmailValidationService"
import { InlineEmailValidatingLoader } from "@components/PersonalDetailsForm/PersonalDetailsForm.styles"
import { EmailRegex, NameRegex } from "@utils/Constant"
import LeadGenSignup from "@components/LeadGeneration/LeadGenSignup"
import ThankYouBanner from "@components/LeadGeneration/ThankYouBanner"

interface SignupProps {
  apiUrl: string
  emailValidationToken: string
  title: string
  button: ButtonType
  carMake: any
  trailerMake: any
  motorbikeMake: any
}

interface SignupState {
  currentEmail: string
  validatingEmail: boolean
  emailIsValid?: boolean
  toggleSignup: boolean
  toggleThankYou: boolean
  values: any
}

class SignupBanner extends React.Component<SignupProps, SignupState> {
  constructor(props: SignupProps) {
    super(props)
    this.state = {
      currentEmail: "",
      validatingEmail: false,
      emailIsValid: undefined,
      toggleSignup: false,
      toggleThankYou: false,
      values: {}
    }
  }
  validationSchema = Yup.object({
    firstName: Yup.string().required("First Name is required").matches(NameRegex, "Enter valid characters"),
    lastName: Yup.string().required("Last Name is required").matches(NameRegex, "Enter valid characters"),

    email: Yup.string()
      .required("Email is required")
      .matches(EmailRegex, "Must enter a valid email address"),
  })

  intialStatus = {
    isSubmitting: false,
    isSubmitted: false,
  }

  insitialValues = {
    firstName: "",
    lastName: "",
    email: "",
  }

  validateEmailAddress = (value: any) => {
    if (this.state.currentEmail !== value) {
      this.setState({
        currentEmail: value,
        validatingEmail: true,
        emailIsValid: undefined,
      })
      return EmailValidationService.validate(
        this.props.emailValidationToken,
        value,
        false
      ).then((result: any) => {
        if (result.success == true) {
          this.setState({ validatingEmail: false, emailIsValid: true })
        } else {
          this.setState({ validatingEmail: false, emailIsValid: false })
          return "Invalid Email"
        }
      })
    } else if (this.state.emailIsValid == false) {
      return "Invalid Email"
    }
  }
  submitForm = (values: any, actions: any) => {
    if (!this.state.validatingEmail) {
      actions.setStatus({
        isSubmitting: true,
        isSubmitted: false,
      })

      SubscriptionService.subscribeUserRequest(
        this.props.apiUrl,
        {
          firstname: values.firstName,
          lastname: values.lastName,
          email: values.email,
        },
        "Website Banner",
        {
          campaignName: 'KiwiVIP'
        }, false,
        '',
        ''
      ).then(data => {
        actions.setStatus({
          isSubmitting: false,
          isSubmitted: true
        })
      })

      this.setState({toggleSignup: true, values: values})


      actions.setStatus({
        isSubmitting: false,
        isSubmitted: true,
      })
    } else {
      actions.setSubmitting(false)
    }
  }

  handleSignup = (postData: any) => {
    var values = this.state.values
    SubscriptionService.subscribeUserRequest(
      this.props.apiUrl,
      {
        firstname: values.firstName,
        lastname: values.lastName,
        email: values.email,
      },
      "Website Banner",
      {
        campaignName: 'KiwiVIP'
      }, false,
      postData['vehicleType'],
      postData['vehicleMake']
    ).then(data => {
      this.setState({
        toggleSignup: false,
        toggleThankYou: true
      }, ()=>{
        setTimeout(() => {
          this.setState({
            toggleThankYou: false
          })
        }, 5000);

      })
    })
  }


  toggleSignup = () => {
      this.setState({ toggleSignup: !this.state.toggleSignup })
  }

  toggleThankYou = () => {
    this.setState({ toggleThankYou: !this.state.toggleThankYou })
  }

  render() {
    const { title, button, carMake, motorbikeMake, trailerMake } = this.props
    return (
      <BannerSegment basic>
        <Formik
          initialValues={this.insitialValues}
          validationSchema={this.validationSchema}
          validateOnChange={false}
          initialStatus={this.intialStatus}
          onSubmit={(values: any, actions: any) =>
            this.submitForm(values, actions)
          }
        >
          {props => (
            <Form inverted onSubmit={props.handleSubmit}>
              <BannerGrid columns={16}>
                <Grid.Row>
                  <BannerGridTitleColumn
                    mobile={16}
                    tablet={16}
                    computer={16}
                    largeScreen={5}
                    widescreen={5}
                    textAlign="center"
                  >
                    <BannerTitle>{title}</BannerTitle>
                  </BannerGridTitleColumn>
                  {!props.status.isSubmitted && (
                    <Grid.Column
                      mobile={16}
                      tablet={16}
                      computer={16}
                      largeScreen={11}
                      widescreen={11}
                    >
                      <Grid width={14}>
                        <Grid.Row>
                          <BannerGridColumn
                            mobile={8}
                            tablet={8}
                            computer={3}
                            textAlign="center"
                          >
                            {props.errors.firstName && 
                            props.touched.firstName && (
                              <KpFormInputErrorMessage>
                                {props.errors.firstName}
                              </KpFormInputErrorMessage>
                            ) }
                            <KpFormField
                              error={
                                props.errors.firstName &&
                                props.touched.firstName
                              }
                              borderRadius={5}
                            >
                              <input
                                id="firstName"
                                name="firstName"
                                type="text"
                                data-private="lipsum"
                                aria-label="First Name"
                                disabled={props.status.isSubmitting}
                                placeholder="First Name"
                                onChange={props.handleChange}
                                onBlur={props.handleBlur}
                                value={props.values.firstName}
                              ></input>
                            </KpFormField>
                          </BannerGridColumn>
                          <BannerGridColumn
                            mobile={8}
                            tablet={8}
                            computer={3}
                            textAlign="center"
                          >
                            {props.errors.lastName && props.touched.lastName && (
                              <KpFormInputErrorMessage>
                                {props.errors.lastName}
                              </KpFormInputErrorMessage>
                            )}
                            <KpFormField
                              error={
                                props.errors.lastName && props.touched.lastName
                              }
                              borderRadius={5}
                            >
                              <input
                                id="lastName"
                                name="lastName"
                                aria-label="Last Name"
                                data-private="lipsum"
                                type="text"
                                disabled={props.status.isSubmitting}
                                placeholder="Last Name"
                                onChange={props.handleChange}
                                onBlur={props.handleBlur}
                                value={props.values.lastName}
                              ></input>
                            </KpFormField>
                          </BannerGridColumn>
                          <BannerGridColumn
                            mobile={16}
                            tablet={16}
                            computer={4}
                            textAlign="center"
                          >
                            {props.errors.email && props.touched.email && (
                              <KpFormInputErrorMessage>
                                {props.errors.email}
                              </KpFormInputErrorMessage>
                            )}
                            <KpFormField
                              error={props.errors.email && props.touched.email}
                              borderRadius={5}
                            >
                              <FormikFieldEmailValidationInput
                                id="email"
                                name="email"
                                type="email"
                                data-private="lipsum"
                                aria-label="Email Address"
                                disabled={props.status.isSubmitting}
                                placeholder="Email"
                                onChange={props.handleChange}
                                inputValid={this.state.emailIsValid}
                                validate={this.validateEmailAddress}
                                onBlur={props.handleBlur}
                                value={props.values.email}
                              />

                              <InlineEmailValidatingLoader
                                active={this.state.validatingEmail}
                                inline
                                size="small"
                              />
                            </KpFormField>
                          </BannerGridColumn>
                          <BannerGridButtonColumn
                            mobile={16}
                            tablet={16}
                            computer={2}
                            textAlign="center"
                            id="sbsmt"
                          >
                            <KpButton
                              id="sbsmt"
                              fullWidth="mobile"
                              type="submit"
                              loading={props.status.isSubmitting}
                              buttonType='primary'
                              color={theme.brand.colors.blue}
                              link={button.url}
                              disabled={!(props.isValid && props.dirty)}                              
                              darkTheme={true}
                            >
                              {button.text as string}
                            </KpButton>
                          </BannerGridButtonColumn>
                        </Grid.Row>
                      </Grid>
                    </Grid.Column>
                  )}
                  {props.status.isSubmitted && (
                    <BannerGridColumn
                      verticalAlign="middle"
                      mobile={16}
                      tablet={16}
                      computer={10}
                      textAlign="right"
                    >
                      <Paragraph1 color={theme.brand.colors.white}>
                        <center>Thanks for subscribing!</center>
                      </Paragraph1>
                    </BannerGridColumn>
                  )}
                </Grid.Row>
              </BannerGrid>
            </Form>
          )}
        </Formik>

        <LeadGenSignup
            combination = {""}
            apiUrl={this.props.apiUrl}
            modalOpen={this.state.toggleSignup}
            onClose={this.toggleSignup}
            carMake = {carMake}
            motorbikeMake = {motorbikeMake}
            trailerMake = {this.props.trailerMake}
            handleSignup = {this.handleSignup}
            />
        <ThankYouBanner
            email = {this.state.currentEmail}
            apiUrl={this.props.apiUrl}
            modalOpen={this.state.toggleThankYou}
            onClose={this.toggleThankYou}
            />
      </BannerSegment>
    )
  }
}

export default SignupBanner
