import React, { Fragment } from "react"
import { Checkbox, Grid, Modal } from "semantic-ui-react";
import { isMobile } from "@utils/Helpers";
import { ModalWrapper } from "./ThankYouBanner.styles";
import { KpFormDropdown, KpFormField } from "@styles/Global.forms";
import { KpButton } from "@components/elements/index";
import { ButtonTypes } from "@utils/Constant";
import theme from "@styles/theme";

interface ThankYouBannerState {
    emailIsValid?: boolean
    currentEmail: string
    validatingEmail: boolean
}

interface ThankYouBannerProps {
    apiUrl:string
    onClose: Function,
    modalOpen: boolean,
    email: string
}

class ThankYouBanner extends React.Component<ThankYouBannerProps,ThankYouBannerState>{

    constructor(props:ThankYouBannerProps){
        super(props);
        this.state = {
            currentEmail: "",
            emailIsValid: false,
            validatingEmail: false,
        }
    }

    handleClose = () => {
        this.props.onClose();
    }

    render() {

        return (
            <Modal className="lead-gen-modal" open={this.props.modalOpen} onClose={this.handleClose} centered={true} size={isMobile() ? "fullscreen" : "tiny"}>

                <ModalWrapper>
                <div className="close" onClick={this.handleClose}>
                  <svg width="13" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12.3828 2.88281C12.8711 2.39453 12.8711 1.60156 12.3828 1.11328C11.8945 0.625 11.1016 0.625 10.6133 1.11328L6.5 5.23047L2.38281 1.11719C1.89453 0.628906 1.10156 0.628906 0.613281 1.11719C0.125 1.60547 0.125 2.39844 0.613281 2.88672L4.73047 7L0.617188 11.1172C0.128906 11.6055 0.128906 12.3984 0.617188 12.8867C1.10547 13.375 1.89844 13.375 2.38672 12.8867L6.5 8.76953L10.6172 12.8828C11.1055 13.3711 11.8984 13.3711 12.3867 12.8828C12.875 12.3945 12.875 11.6016 12.3867 11.1133L8.26953 7L12.3828 2.88281Z" fill="#707070"/>
                  </svg>
                </div>
                    <Grid.Column mobile={16} tablet={16} computer={16}>
                    <div className="title">Thank You!</div>                    
                    <div className="center">
                    <div className="email flex inline items-center justify-center">
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.00033 16.3333C13.0504 16.3333 16.3337 13.05 16.3337 8.99996C16.3337 4.94987 13.0504 1.66663 9.00033 1.66663C4.95024 1.66663 1.66699 4.94987 1.66699 8.99996C1.66699 13.05 4.95024 16.3333 9.00033 16.3333Z" stroke="#3DAE2B" stroke-width="2"/>
                            <path d="M5.66797 8.88803L7.96452 11.1847L12.3346 6.8147" stroke="#3DAE2B" stroke-width="2"/>
                        </svg>
                        <span>{this.props.email.toLowerCase()}</span>
                    </div>
                    </div>
                    <br/>
                    <div className="tnc">This window will close in 5</div>
                    <br/>
                    <br/>
                    </Grid.Column>
                </ModalWrapper>
            </Modal>)

    }
}

export default ThankYouBanner